<template>
    <div>
        <AAlert
            v-if="errorMessage"
            type="error"
            :message="errorMessage"
            banner
            closable
            @close="errorMessage = null"/>

        <!-- filter -->
        <ARow :gutter="[8, 8]">
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterCategory
                    style="width:100%;"
                    v-model:value="state.params.category"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterSubCategory
                    style="width:100%;"
                    v-model:value="state.params.subcategory"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterBrand
                    style="width:100%;"
                    is_competitor
                    v-model:value="state.params.brand"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <FilterProduct
                    style="width:100%;"
                    is_competitor
                    v-model:value="state.params.product"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AInputSearch
                    allow-clear
                    placeholder="Cari..."
                    style="width:100%"
                    v-model:value="state.params.q"/>
            </ACol>
            <ACol :xl="6" :md="8" :sm="12" :span="24">
                <AButton
                    type="primary"
                    title="cari"
                    @click="fetchDataList"
                    :loading="state.isFetching">
                    <span
                        v-if="!state.isFetching"
                        class="fa fa-search"
                        aria-hidden="true"/>
                    <span v-else>
                        Memuat Data ...
                    </span>
                </AButton>
            </ACol>
        </ARow>

        <!-- button action menu -->
        <div class="row justify-content-end mt-4">
            <div class="col-lg-6 col-md-12"></div>
            <div class="col-lg-6 col-md-12 text-right">
                <ASpace>
                    <ATooltip
                        v-if="hasPermissions(['product-competitors.store'])"
                        title="Tambahkan">
                        <AButton
                            type="primary"
                            @click="btnForm">
                            <i class="fa fa-plus" aria-hidden="true"></i>
                        </AButton>
                    </ATooltip>
                    <ATooltip
                        title="Download excel">
                        <DownloadExcel
                            :url="state.endpoint.current"
                            :params="queryParams()"
                            @errors="errorMessage"/>
                    </ATooltip>
                </ASpace>
            </div>
        </div>

        <!-- list table -->
        <div class="table-responsive text-nowrap mt-4">
            <MdTable
                :columns="state.columns"
                :data-source="state.data"
                size="small"
                :row-class-name="(record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                :pagination="{
                    showSizeChanger: true,
                    pageSizeOptions: ['5', '10', '20', '30', '50', '75'],
                    showTotal: (total, range) => `Menampilkan ${range[0]}-${range[1]} Hasil ${total} `,
                    total: state.meta.total,
                    pageSize: state.meta.per_page,
                    current: state.meta.page,
                }"
                @change="handleTableChange"
                :loading="state.isFetching">

                <template #no="{ index }">
                    <span>
                        {{ (state.meta.page - 1) * state.meta.per_page + 1 + index }}
                    </span>
                </template>

                <template #status="{ text }">
                    <ATag v-if="text" color="#108ee9">Aktif</ATag>
                    <ATag v-else color="grey">Tidak Aktif</ATag>
                </template>

                <template #action="{ record }">
                    <ASpace>
                        <ATooltip
                            v-if="hasPermissions(['product-competitors.show'])"
                            title="Detail">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record, true)">
                                <i class="fe fe-eye" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasPermissions(['product-competitors.update'])"
                            title="Edit">
                            <AButton
                                class="button"
                                size="small"
                                @click="btnForm(record)">
                                <i class="fe fe-edit" />
                            </AButton>
                        </ATooltip>
                        <ATooltip
                            v-if="hasPermissions(['product-competitors.destroy'])"
                            title="Hapus">
                            <AButton
                                class="button"
                                size="small"
                                :loading="record.isDelete"
                                @click="btnDelete(record)">
                                <i class="fe fe-trash" />
                            </AButton>
                        </ATooltip>
                    </ASpace>
                </template>
            </MdTable>
        </div>

        <!-- form -->
        <Form
            v-if="visibleFormModal"
            v-model:visible="visibleFormModal"
            v-model:item="visibleFormItemModal"
            @success="fetchDataList"/>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted } from 'vue'
import { Modal, message } from 'ant-design-vue'
import apiClient from '@/services/axios'
import {
    FilterBrand,
    FilterCategory,
    FilterSubCategory,
    FilterProduct,
} from '@/components/filter'
import DownloadExcel from '@/components/Molecules/DownloadExcel'
import Form from './Form'
import {
    hasRoles,
    hasPermissions,
    ROLE_ADMIN_BK,
} from '@/helpers'
import {
  updateUrlWithQuery,
  extractQueryParams,
} from '@/helpers/queryparams'
import { includes } from 'lodash'

export default defineComponent({
    components: {
        FilterBrand,
        FilterCategory,
        FilterSubCategory,
        FilterProduct,
        DownloadExcel,
        Form,
    },
    setup() {
        const errorMessage = ref()
        const state = reactive({
            columns: [
                {
                    title: 'NO',
                    slots: { customRender: 'no' },
                },
                {
                    title: 'Kode Produk',
                    dataIndex: 'code',
                },
                {
                    title: 'Nama Produk',
                    dataIndex: 'name',
                },
                {
                    title: 'Kategori',
                    dataIndex: 'category',
                },
                {
                    title: 'Sub Kategori',
                    dataIndex: 'subcategory',
                },
                {
                    title: 'Brand',
                    dataIndex: 'brand_name',
                },
                {
                    title: 'Supplier',
                    dataIndex: 'supplier',
                },
                {
                    title: 'Status',
                    dataIndex: 'active',
                    slots: { customRender: 'status' },
                },
                {
                    title: 'Action',
                    key: 'action',
                    slots: { customRender: 'action' },
                },
            ],
            endpoint: {
                current: '/api/product-competitors',
            },
            data: [],
            meta: {
                per_page: 10,
                page: 1,
                total: 0,
            },
            isFetching: false,
            params: extractQueryParams({
                q: '',
                brand: [],
                product: [],
                category: [],
                subcategory: [],
                page: 1,
                "per-page": 10,
            }),
        })

        const handleTableChange = (page, filters, sorter) => {
            state.params.page = page.current
            state.params["per-page"] = page.pageSize

            fetchDataList()
        }

        const queryParams = () => {
            let params = state.params

            return updateUrlWithQuery(params)
        }

        // fetch list data
        const fetchDataList = () => {
            state.isFetching = true
            
            apiClient
                .get(state.endpoint.current, {
                    params: queryParams(),
                })
                .then(({ data }) => {
                    const { items, _meta } = data
                    items.forEach((item) => {
                        item.isDelete = false
                    })

                    state.data = items
                    state.meta.page = _meta.currentPage
                    state.meta.per_page = _meta.perPage
                    state.meta.total = _meta.totalCount
                })
                .finally(() => {
                    state.isFetching = false
                })
        }

        const btnDelete = (record) => {
            Modal.confirm({
                title: 'Konfirmasi Hapus',
                content: 'Apakah Anda ingin hapus item tersebut?',
                onOk() {
                    record.isDelete = true

                    apiClient
                        .delete(`${state.endpoint.current}/${record.id}`)
                        .then((response) => {
                            fetchDataList()
                            message.success('Berhasil dihapus')
                        })
                        .catch(e => message.error('Gagal menghapus!'))
                        .finally(() => {
                            record.isDelete = false
                        })
                },
                onCancel() { },
            })
        }

        // handle form
        const visibleFormModal = ref(false);
        const visibleFormItemModal = ref(null);

        const btnForm = (item = null, readOnly = false) => {
            visibleFormModal.value = true
            visibleFormItemModal.value = {
                ...item,
                readOnly,
            }
        }

        // handle vue
        onMounted(() => {
            fetchDataList()

            // delete column action ketika semua permission tidak ada
            if (!hasPermissions(['product-competitors.show', 'product-competitors.update', 'product-competitors.destroy'])) {
                state.columns = state.columns.filter(item => {
                    if (!includes(['action'], item.key)) {
                        return item
                    }
                })
            }
        })

        return {
            visibleFormModal,
            visibleFormItemModal,
            btnForm, // create or update
            btnDelete,
            fetchDataList,
            state,
            handleTableChange,
            errorMessage,
            queryParams,
            // only variable role
            hasRoles,
            hasPermissions,
            ROLE_ADMIN_BK,
        }
    },
})
</script>
