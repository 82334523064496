<template>
    <a-modal
        :visible="visible"
        :title="state.title"
        @ok="ConversionPoint"
        @cancel="handleModalCancel"
        width="50%"
        :destroy-on-close="true"
        :footer="null">
    
        <AForm
            ref="formRef"
            class="myform"
            :model="form"
            :rules="state.rules"
            @finish="handleOk"
            :wrapper-col="{ span: 14 }"
            :scroll-to-first-error="true">

            <!-- kode produk -->
            <AFormItem
                label="Kode SAP"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="code"
                has-feedback>
                <AInput
                    placeholder="kode SAP"
                    :disabled="form.readOnly"
                    v-model:value="form.code" />
            </AFormItem>

            <!-- nama produk -->
            <AFormItem
                label="Nama Produk"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="name"
                has-feedback>
                <AInput
                    placeholder="nama produk"
                    :disabled="form.readOnly"
                    v-model:value="form.name" />
            </AFormItem>

            <!-- brand -->
            <AFormItem
                label="Brand"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="brand_id"
                has-feedback>
                <FilterBrand
                    style="width:100%;"
                    :mode="null"
                    :disabled="form.readOnly"
                    is_competitor
                    v-model:value="form.brand_id"/>
            </AFormItem>

            <!-- harga -->
            <AFormItem
                label="Harga"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                required
                name="price"
                has-feedback>
                <AInputNumber
                    placeholder="harga"
                    style="width:100%"
                    :disabled="form.readOnly"
                    v-model:value="form.price" />
            </AFormItem>

            <!-- kategori -->
            <AFormItem
                label="Kategori"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="category_id"
                has-feedback>
                <FilterCategory
                    style="width:100%;"
                    :mode="null"
                    :disabled="form.readOnly"
                    v-model:value="form.category_id"/>
            </AFormItem>

            <!-- sub kategori -->
            <AFormItem
                label="Sub Kategori"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="subcategory_id"
                has-feedback>
                <FilterSubCategory
                    style="width:100%;"
                    :mode="null"
                    :disabled="form.readOnly"
                    v-model:category="form.category_id"
                    v-model:value="form.subcategory_id"/>
            </AFormItem>

            <!-- type -->
            <AFormItem
                label="Tipe"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="type"
                has-feedback>
                <FilterProductType
                    style="width:100%;"
                    :mode="null"
                    :disabled="form.readOnly"
                    v-model:value="form.type"/>
            </AFormItem>

            <!-- suplier -->
            <AFormItem
                label="Suplier"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="supplier_id"
                has-feedback>
                <FilterSupplier
                    style="width:100%;"
                    :mode="null"
                    is_create
                    is_competitor
                    :disabled="form.readOnly"
                    v-model:value="form.supplier_id"/>
            </AFormItem>

            <!-- deskripsi -->
            <AFormItem
                label="Detail Produk"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="description"
                has-feedback>
                <ATextarea
                    placeholder="tulis detail produk"
                    :disabled="form.readOnly"
                    v-model:value="form.description" />
            </AFormItem>

            <!-- foto -->
            <AFormItem
                label="Foto Produk"
                label-align="left"
                :label-col="{ sm: { span: 4 } }"
                :wrapper-col="{ sm: { span: 24 - 4 } }"
                name="image"
                has-feedback>
                <AInput
                    v-if="!form.readOnly"
                    type="file"
                    accept="image/png, image/gif, image/jpeg"
                    @change="e => onFileChange(e, 'image')"/>
                <template v-else>
                    <img
                        v-if="form.image_url"
                        :src="form.image_url"
                        style="width:300px;height:300px;object-fit:cover;"
                        alt="image"/>
                    <span v-else>Tidak ada foto produk.</span>
                </template>
            </AFormItem>

            <ARow
                v-if="!form.readOnly"
                class="form-row"
                type="flex"
                justify="end">
                <ACol>
                    <AFormItem>
                        <a-button
                            type="primary"
                            html-type="submit"
                            :loading="form.busy"
                            :disabled="form.busy">{{
                            !form.id ? 'Simpan' : 'Perbarui'
                        }}</a-button>
                    </AFormItem>
                </ACol>
            </ARow>
        </AForm>

        <div v-if="state.errors">
            <ASpace>
                <AAlert
                    v-for="(item, index) in state.errors"
                    type="error"
                    :key="index"
                    :message="item"
                    banner
                    closable
                    @close="state.errors = []" />
            </ASpace>
        </div>

        <div v-if="form.errors.any()">
            <AAlert
                v-for="(item, index) in form.errors.all()"
                type="error"
                class="mb-2"
                :key="index"
                :message="item.message"
                banner
                closable />
        </div>
    </a-modal>
</template>
<script>
import {
    defineComponent,
    onMounted,
    reactive,
    ref,
} from 'vue'
import Form from 'vform'
import { Modal, message } from 'ant-design-vue'
import {
    FilterBrand,
    FilterCategory,
    FilterSubCategory,
    FilterProductType,
    FilterSupplier,
} from '@/components/filter'
import {
    ROLE_ADMIN_BK,
    hasRoles,
} from '@/helpers'

export default defineComponent({
    components: {
        FilterBrand,
        FilterCategory,
        FilterSubCategory,
        FilterProductType,
        FilterSupplier,
    },
    props: {
        visible: [Boolean],
        item: {
            type: Object,
            default: () => ({
                id: null,
                readOnly: false,
            }),
        },
    },
    emits: ['update:visible'],
    setup(props, { emit }) {
        const form = reactive(new Form({
            id: props.item.id,
            code: null,
            name: null,
            brand_id: null,
            description: null,
            category_id: null,
            subcategory_id: null,
            type: null,
            supplier_id: null,
            image: null,
            price: null,
        }))

        const formRef = ref();

        const state = reactive({
            title: 'Lihat Master Produk Kompetitor',
            endpoint: {
                create: '/api/product-competitors',
                update: `/api/product-competitors/${props.item.id}?_method=PUT`,
            },
            data: [],
            page: 1,
            per_page: 1,
            total: 0,
            loading: false,
            rules: {
                name: [
                    {
                        required: true,
                        message: 'Nama produk tidak boleh kosong!',
                    },
                ],
                brand_id: [
                    {
                        required: true,
                        message: 'Brand tidak boleh kosong!',
                    },
                ],
                price: [
                    {
                        required: true,
                        message: 'Harga tidak boleh kosong!',
                    },
                ],
            },
            errors: [],
        })

        const handleModalCancel = () => {
            emit('update:visible', false)
            emit('update:item', null)
        }

        const handleOk = async () => {

            // validation form
            await formRef.value.validate()
                .catch(() => {});

            let request = (!form.id)
                ? form.post(state.endpoint.create)
                : form.post(state.endpoint.update)

            request.then(({ data }) => {
                    message.success((!form.id) ? 'Berhasil menyimpan.' : 'Berhasil memperbarui.')

                    handleModalCancel()
                    emit('success', data)
                })
                .catch(({ response: { data, active } }) => {
                    if (data.error) {
                        message.error(data.error)
                    }

                    if (data.errors) {
                        state.errors = data.errors
                    }
                })
        }

        const onFileChange = (e) => {
            var files = e.target.files || e.dataTransfer.files
            if (!files.length) return

            const file = files[0]
            const maxSize = 2 * 1024 * 1024 // 2MB

            if (file.size > maxSize) {
                message.warning('Ukuran harus di bawah 2MB!')
                return
            }

            form.image = file
        }

        // handle func vue
        onMounted(() => {
            if (!form.id) {
                form.reset()
            } else {
                // edit form
                form.code = props.item.code
                form.name = props.item.name
                form.brand_id = props.item.brand_id
                form.description = props.item.description
                form.price = props.item.price
                form.category_id = props.item.category_id
                form.subcategory_id = props.item.subcategory_id
                form.type = props.item.type_id
                form.supplier_id = props.item.supplier_id
                form.image_url = props.item.image_url
                form.price = props.item.price

                form.readOnly = props.item.readOnly
            }

            if (!props.item.readOnly) {
                state.title = `${!form.id ? 'Tambah' : 'Edit'} Master Produk Kompetitor`
            }
        })

        return {
            formRef,
            handleOk,
            form,
            state,
            handleModalCancel,
            onFileChange,
            // only variable role
            hasRoles,
            ROLE_ADMIN_BK,
        }
    },
})
</script>

<style lang="scss" scoped>
@import '@/assets/scss/form.scss';
</style>
